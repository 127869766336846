/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    code: "code",
    strong: "strong",
    pre: "pre",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "migrate-from-emotion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#migrate-from-emotion",
    "aria-label": "migrate from emotion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Migrate from Emotion"), "\n", React.createElement(_components.p, null, "Differences with Emotion and migration guide to xstyled."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "differences-with-emotion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#differences-with-emotion",
    "aria-label": "differences with emotion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Differences with Emotion"), "\n", React.createElement(_components.h3, {
    id: "declarative-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#declarative-components",
    "aria-label": "declarative components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Declarative components"), "\n", React.createElement(_components.p, null, "xstyled is similar to Emotion ", React.createElement(_components.code, null, "css"), " prop with some benefits. As a picture is better than 1000 words, take this example:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using Emotion")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsx jsx */\nimport { jsx, css } from '@emotion/react'\n\nconst Button = ({ children }) => {\n  return (\n    <button\n      css={{\n        padding: '0.5rem 1rem',\n        color: 'white',\n        borderRadius: '0.375rem',\n        fontWeight: 600,\n        transition: 'background-color cubic-bezier(0.4, 0, 0.2, 1) 150ms',\n        backgroundColor: '#10b981',\n        '&:hover': {\n          backgroundColor: '#065f46',\n        },\n        '&:focus': {\n          outline: 'none',\n          boxShadow: '0 0 0 3px #10b98180',\n        },\n      }}\n    >\n      {children}\n    </button>\n  )\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using xstyled")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { x } from '@xstyled/emotion'\n\nconst Button = ({ children }) => (\n  <x.button\n    py={2}\n    px={4}\n    color=\"white\"\n    borderRadius=\"md\"\n    fontWeight=\"semibold\"\n    transition\n    bg={{ _: 'emerald-500', hover: 'emerald-800' }}\n    outline={{ focus: 'none' }}\n    ring={{ focus: 'none' }}\n    ringColor={{ focus: 'emerald-500-a50' }}\n  >\n    {children}\n  </x.button>\n)\n")), "\n", React.createElement(_components.p, null, "As you can see xstyled is fully declarative and much more productive than using emotion."), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "/docs/declarative-components/"
  }, "declarative components guide"), " to learn more about this approach."), "\n", React.createElement(_components.h3, {
    id: "enhanced-css",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#enhanced-css",
    "aria-label": "enhanced css permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Enhanced ", React.createElement(_components.code, null, "css")), "\n", React.createElement(_components.p, null, "We know you could not be convinced by ", React.createElement(_components.code, null, "x.*"), " approach. That's why xstyled could help you to be more productive with enhanced ", React.createElement(_components.code, null, "css"), " approach too."), "\n", React.createElement(_components.p, null, "Most projects follow a design system, a set of space, colors, fonts.. that define the constraints and the universe of a project."), "\n", React.createElement(_components.p, null, "To follow a design system with emotion, the common way is to use the theme."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using Emotion")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "/** @jsx jsx */\nimport { jsx } from '@emotion/react'\n\nconst Button = ({ children }) => {\n  return (\n    <button\n      css={(theme) => ({\n        padding: `${theme.space[2]} ${theme.space[4]}`,\n        color: theme.colors.white,\n        borderRadius: theme.radii.md,\n        fontWeight: theme.fontWeights.semibold,\n        transition: theme.transition.default,\n        backgroundColor: theme.colors['emerald-500'],\n        '&:hover': {\n          backgroundColor: theme.colors['emerald-800'],\n        },\n        '&:focus': {\n          outline: 'none',\n          boxShadow: theme.shadows['emerald-ring'],\n        },\n      })}\n    >\n      {children}\n    </button>\n  )\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using xstyled")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "/** @jsx jsx */\nimport { jsx } from '@xstyled/emotion'\n\nconst Button = ({ children }) => {\n  return (\n    <button\n      css={{\n        paddingTop: 2,\n        color: 'white',\n        borderRadius: 'md',\n        fontWeight: 'semibold',\n        transition: 'default',\n        backgroundColor: 'emerald-500',\n        '&:hover': {\n          backgroundColor: 'emerald-800',\n        },\n        '&:focus': {\n          outline: 'none',\n          boxShadow: 'emerald-ring',\n        },\n      }}\n    >\n      {children}\n    </button>\n  )\n}\n")), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "/docs/magic-styled-components/"
  }, "enhanced styled components guide"), " to learn more about this approach."), "\n", React.createElement(_components.h3, {
    id: "responsive-utilities",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive-utilities",
    "aria-label": "responsive utilities permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive utilities"), "\n", React.createElement(_components.p, null, "xstyled has great responsive utilities to simplify usage of media queries."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using Emotion")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsx jsx */\nimport { jsx } from '@emotion/react'\n\nconst Button = ({ children }) => {\n  return (\n    <button css={{ width: 200, '@media (min-width: 768px)': { width: 300 } }}>\n      {children}\n    </button>\n  )\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Using xstyled")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import { x } from '@xstyled/emotion'\n\nconst Button = ({ children }) => {\n  return <x.button w={{ _: 200, md: 300 }}>{children}</x.button>\n}\n")), "\n", React.createElement(_components.h2, {
    id: "replacing-emotion-by-xstyled",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#replacing-emotion-by-xstyled",
    "aria-label": "replacing emotion by xstyled permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Replacing Emotion by xstyled"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "@xstyled/emotion"), " is a replacement of ", React.createElement(_components.code, null, "@emotion/react"), " and ", React.createElement(_components.code, null, "@emotion/styled"), ". You can safely replace ", React.createElement(_components.code, null, "@emotion/react"), " and ", React.createElement(_components.code, null, "@emotion/styled"), " by ", React.createElement(_components.code, null, "@xstyled/emotion"), "."), "\n", React.createElement(_components.p, null, "Once your code is using xstyled, you can start using ", React.createElement(_components.a, {
    href: "/docs/declarative-components/"
  }, "declarative components (", React.createElement(_components.code, null, "x.*"), ")"), " and ", React.createElement(_components.a, {
    href: "/docs/styled-components/"
  }, "enhanced styled components (", React.createElement(_components.code, null, "styled.*"), ")"), "."), "\n", React.createElement(_components.h3, {
    id: "jsx-pragma",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#jsx-pragma",
    "aria-label": "jsx pragma permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "JSX Pragma"), "\n", React.createElement(_components.p, null, "JSX Pragma is exposed in ", React.createElement(_components.code, null, "@xstyled/emotion"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "/** @jsx jsx */\nimport { jsx } from '@xstyled/emotion'\n\nconst Button = ({ children }) => {\n  return (\n    <button\n      css={{\n        paddingTop: 2,\n        color: 'white',\n        borderRadius: 'md',\n      }}\n    >\n      {children}\n    </button>\n  )\n}\n")), "\n", React.createElement(_components.h3, {
    id: "babel-preset",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#babel-preset",
    "aria-label": "babel preset permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Babel preset"), "\n", React.createElement(_components.p, null, "To avoid the ", React.createElement(_components.code, null, "/** @jsx jsx */"), " you can use ", React.createElement(_components.code, null, "@xstyled/babel-preset-emotion-css-prop"), ". It has the same interface as ", React.createElement(_components.code, null, "@emotion/babel-preset-css-prop"), ", see ", React.createElement(_components.a, {
    href: "https://emotion.sh/docs/css-prop#babel-preset"
  }, "Emotion documentation"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, ".babelrc")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  \"presets\": [\"@xstyled/babel-preset-emotion-css-prop\"]\n}\n")), "\n", React.createElement(_components.h3, {
    id: "babel-plugin",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#babel-plugin",
    "aria-label": "babel plugin permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Babel Plugin"), "\n", React.createElement(_components.p, null, "You may want to use ", React.createElement(_components.a, {
    href: "https://emotion.sh/docs/babel"
  }, "Emotion Babel plugin"), " for minification of styles, dead code elimination, components as selectors, and a nicer debugging experience."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, ".babelrc")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  ...,\n  \"presets\": [\n    [\n      \"@xstyled/babel-preset-emotion-css-prop\",\n      {\n        // @emotion/babel-plugin options\n        // https://emotion.sh/docs/@emotion/babel-plugin\n      }\n    ]\n  ]\n}\n")), "\n", React.createElement(_components.p, null, "Note: ", React.createElement(_components.code, null, "importMap"), " has some known issues coming from Emotion side"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/xstyled/issues/286"
  }, "Emotion not compatible with styled.box")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/gregberge/xstyled/issues/285"
  }, "CSS prop doesnt have access to the theme")), "\n"), "\n", React.createElement(_components.h3, {
    id: "difference-in-css-prop-usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#difference-in-css-prop-usage",
    "aria-label": "difference in css prop usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Difference in ", React.createElement(_components.code, null, "css"), " prop usage"), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "css"), " prop with ", React.createElement(_components.code, null, "@xstyled/emotion"), " is slightly different from ", React.createElement(_components.code, null, "css"), " prop with ", React.createElement(_components.code, null, "@emotion/react"), "."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "theme"), " is accessible as a destructured object ", React.createElement(_components.code, null, "{ theme }")), "\n", React.createElement(_components.li, null, "When using a function, you have to wrap your style in ", React.createElement(_components.code, null, "css")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "With Emotion")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "/** @jsx jsx */\nimport { jsx } from '@emotion/react'\n\nconst Button = ({ children }) => {\n  return (\n    <button\n      css={(theme) => ({\n        paddingTop: theme.space[1],\n      })}\n    >\n      {children}\n    </button>\n  )\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "With xstyled")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "/** @jsx jsx */\nimport { jsx } from '@xstyled/emotion'\n\nconst Button = ({ children }) => {\n  return (\n    <button\n      css={({ theme }) =>\n        css({\n          paddingTop: theme.space[1],\n        })\n      }\n    >\n      {children}\n    </button>\n  )\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
